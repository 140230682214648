body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* background-color: aqua; */
}
.box{
  width:330px;
}
.dash{
display:flex;
justify-content:center;
}

.leftDash{
  width:240px;
  position: absolute;
  left: 8px;
}
.rightDash{
  position:absolute;
  left: 300px;
}
.overX{
  overflow-x: scroll;
}
